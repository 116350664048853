.faqs__container {
    width: 100%;
    height: 100vh;
    
    background-color: var(--white);
    color: var(--black);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 6rem;
}

.faqs__container-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.faqs__container-grid {
    height: 664px;
    width: 664px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.faqs__container-content-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max-width-container);
}

.faqs__container_headers h2 {
    margin-top: -1.25rem;
    margin-bottom: 3rem;
}

#faq_txt {
    opacity: 1;
    /* opacity: 1; */
    max-width: 650px;
}

#faq_h1, #faq_h2 {
    opacity: 1;
    /* opacity: 1; */
}

.faqs__container-content-image {
    height: 715px;
}

.faqs__container-content-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.faqs__container-content-image img {
    height: 100%;
}

.faqs__separator {
    height: 2px;
    width: 80%;
    background-color: var(--black);
    border-radius: 10px;
}

.faqs__secondary__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    width: 100%;
    max-width: var(--max-width-container);

    gap: 2rem;

    margin-top: 0rem;
}

.faqs__separator {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.faqs__secondary__container-questions {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    gap: 2rem;

    width: 100%; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    height: auto;
    max-width: var(--max-width-container);
}

.provamio {
    height: 230px;
    width: 400px;
    background-color: blue;
    border: 0;
    border-radius: 20px;
}





/* TABLET TARGET  */
@media screen and ( max-width: 1600px ) {

    .faqs__container {
        width: 100%;
        height: auto;
        
        background-color: var(--white);
        color: var(--black);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        padding: 2rem;
    }

    .faqs__container-content {
        width: 100%;
    }

    .faqs__container-content-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .faqs__container_headers {
        margin-top: 2rem;
    }

    .faqs__container_headers h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #faq_txt {
        opacity: 1;
        /* opacity: 1; */
        max-width: 95%;
    }
    
    #faq_h1, #faq_h2 {
        opacity: 1;
        /* opacity: 1; */
    }

    .faqs__secondary__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    
        width: 100%;
        gap: 2rem;
    
    }

    .faqs__secondary__container-questions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: center;
        gap: 2rem;
        width: 100%;
        max-width: 560px;
    }

    .faqs__separator {
        height: 1px;
        width: 100%;
        background-color: var(--black);
        border-radius: 10px;
    }
}






/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .faqs__container {
        width: 100%;
        height: auto;
        
        background-color: var(--white);
        color: var(--black);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        padding: 2rem;
    }

    .faqs__container-content {
        width: 100%;
    }

    .faqs__container-content-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .faqs__container_headers {
        margin-top: 2rem;
    }

    .faqs__container_headers h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #faq_txt {
        opacity: 1;
        /* opacity: 1; */
        max-width: 95%;
    }
    
    #faq_h1, #faq_h2 {
        opacity: 1;
        /* opacity: 1; */
    }

    .faqs__secondary__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    
        width: 100%;
        gap: 2rem;
    
    }

    .faqs__secondary__container-questions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    
        gap: 2rem;
        width: 100%;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
    }

    .faqs__separator {
        height: 1px;
        width: 100%;
        background-color: var(--black);
        border-radius: 10px;
    }
}




/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .faqs__container {
        width: 100%;
        height: auto;
        
        background-color: var(--white);
        color: var(--black);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        padding: 2rem;
    }

    .faqs__container-content {
        width: 100%;
    }

    .faqs__container-content-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .faqs__container_headers {
        margin-top: 2rem;
    }

    .faqs__container_headers h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #faq_txt {
        opacity: 1;
        /* opacity: 1; */
        max-width: 95%;
    }
    
    #faq_h1, #faq_h2 {
        opacity: 1;
        /* opacity: 1; */
    }

    .faqs__secondary__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    
        width: 100%;
        gap: 2rem;
    
    }

    .faqs__secondary__container-questions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    
        gap: 2rem;
        width: 100%;
    }

    .faqs__separator {
        height: 1px;
        width: 100%;
        background-color: var(--black);
        border-radius: 10px;
    }
}