.team__container {
    width: 100vw;
    height: calc( 100vh - 100px );
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    padding: 6rem;
}

.team__container-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: var(--max-width-container);
}

.team__container-content-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 6rem;
}

.team__container-content-main-cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 4rem;
}

#tm_txt {
    /* opacity: 1; */
    margin-bottom: 2rem;
    max-width: 750px;
}

.team__container-content-image {
    width: 400px;
}

.team__container-content-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.team__container-content-image img {
    width: 100%;
}





/* TABLET TARGET  */
@media screen and ( max-width: 1024px ) {

    .team__container {
        width: 100%;
        height: auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 2rem;
    }

    .team__container-content-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 2rem;
    }

    .team__container-content-main-cards {
        grid-template-columns: repeat(2, 1fr);
        margin-left: auto;
        margin-right: auto;
    }

}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .team__container {
        width: 100%;
        height: auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 2rem;
    }

    .team__container-content-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 2rem;
    }

    .team__container-content-main-cards {
        /* display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        gap: 2rem; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}






/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .team__container {
        width: 100%;
        height: auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 2rem;
    }

    .team__container-content-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 2rem;
    }

    .team__container-content-main-cards {
        /* display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        gap: 2rem; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}




/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .team__container {
        width: 100%;
        height: auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 2rem;
    }

    .team__container-content-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 2rem;
    }

    .team__container-content-main-cards {
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: center;
        align-self: center;
        gap: 2rem;
    }
}


