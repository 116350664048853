.litepaper__container {
    width: 100%;
    height: calc( 100vh - 100px);
    background-color: var(--white);
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 6rem;

    padding: 0rem 6rem;
}

.litepaper__container-left {
    height: auto;
    width: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.litepaper__container-right {
    display: flex;
    flex: 2;
    flex-direction: column;

    height: auto;
    width: auto;

    overflow: scroll;
}

.moodin-logo-ltp {
    font-size: var(--header-size);
    color: var(--violet);
}

.moodin-litepaper-ltp {
    color: var(--violet);
    margin-bottom: 2rem;
}

.download-btn {
    margin-top: 2rem;

    width: 280px;
    background-color: var(--black);
    color: var(--white);
    border: 0;
    border-radius: 50px;

    font-family: gopher,sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    font-size: 1rem;
    padding: 1rem;

    cursor: pointer;
}

.litepaper-header-text {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: calc( var(--header-size) / 2 );
}

.litepaper-descr {
    /* max-width: 600px; */
    margin-bottom: 4rem;
}

.litepaper-section {
    display: flex;
    flex-direction: column;
    align-items: end;
}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    

}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    

}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    

}