.about__container {
    width: 100vw;
    height: calc( 100vh - 100px );
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    padding: 6rem;
}

.about__container-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: var(--max-width-container);
}

.about__container-content-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.about__container-content-main h2 {
    color: var(--violet);
    margin-top: -1.25rem;
    margin-bottom: 3rem;
}

#abt_txt {
    /* opacity: 0; */
    margin-bottom: 2rem;
    max-width: 750px;
}

/* #abt_h1, #abt_btn, #abt_character {
    opacity: 0;
} */

#abt_h2 {
    /* opacity: 0; */
    font-size: var(--header-size);
}

.about__container-content-image {
    width: 400px;
}

.about__container-content-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.about__container-content-image img {
    width: 100%;
}





/* TABLET TARGET  */
@media screen and ( max-width: 1024px ) {

    .about__container {
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }

    .about__container-content-image {
        /* display: none; */
    }

    #abt_h1 {
        margin-bottom: 2rem;
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .about__container {
        width: 100%;
        height: auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }

    .about__container-content-image {
        display: none;
    }

    #abt_h1 {
        margin-bottom: 2rem;
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .about__container {
        width: 100%;
        height: auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }

    .about__container-content-image {
        display: none;
    }

    #abt_h1 {
        margin-bottom: 2rem;
    }
}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .about__container {
        width: 100%;
        height: auto;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }

    .about__container-content-image {
        display: none;
    }

    #abt_h1 {
        margin-bottom: 2rem;
    }
}