.faquicontainer {
    border: 1px solid var(--violet);
    border-radius: 20px;
}

.faq_btn_expandable {
    width: var(--card-width-normal);

    background-color: var(--white);
    border: 0;
    border-radius: 20px;
    
    padding: 1.5rem;

    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    text-align: left;

    transition: 250ms ease-in-out;
}

.faq_btn_expandable::after {
    content: '\02795';
    color: var(--black);
    font-size: 1rem;
    float: right;
    
    transition: 250ms ease-in-out;
}

.faq_active, .faq_btn_expandable:hover {
    color: var(--violet);
}

.faq_active::after {
    content: "\2796";
    color: transparent;  
    text-shadow: 0 0 0 var(--violet);
}

.faq_btn_expandable:hover::after {
    color: transparent;  
    text-shadow: 0 0 0 var(--violet);
}

.faq_btn-content {
    padding: 0 1.5rem;
    max-height: 0;
    overflow: hidden;
    /* transition: max-height 0.25s ease; */

    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;

    width: var(--card-width-normal);
}

.content_active {
    max-height: 300px;
    margin-bottom: 1.5rem;
}





/* TABLET TARGET  */
@media screen and ( max-width: 1024px ) {

    .faquicontainer {
        border: 1px solid var(--violet);
        border-radius: 8px;
        width: 100%;
    }

    .faq_btn_expandable {
        width: 100%;
    
        background-color: var(--white);
        border: 0;
        border-radius: 8px;
        
        padding: 1rem;
    
        font-family: gopher,sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1rem;
        text-align: left;
    
        transition: 250ms ease-in-out;
    }

    .content_active {
        height: auto;
        width: 100%;
        max-height: auto;
        margin-bottom: 1.5rem;
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .faquicontainer {
        border: 1px solid var(--violet);
        border-radius: 8px;
        width: 100%;
    }

    .faq_btn_expandable {
        width: 100%;
    
        background-color: var(--white);
        border: 0;
        border-radius: 8px;
        
        padding: 1rem;
    
        font-family: gopher,sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1rem;
        text-align: left;
    
        transition: 250ms ease-in-out;
    }

    .content_active {
        height: auto;
        width: 100%;
        max-height: auto;
        margin-bottom: 1.5rem;
    }
}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .faquicontainer {
        border: 1px solid var(--violet);
        border-radius: 8px;
        width: 100%;
    }

    .faq_btn_expandable {
        width: 100%;
    
        background-color: var(--white);
        border: 0;
        border-radius: 8px;
        
        padding: 1rem;
    
        font-family: gopher,sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1rem;
        text-align: left;
    
        transition: 250ms ease-in-out;
    }

    .content_active {
        height: auto;
        width: 100%;
        max-height: auto;
        margin-bottom: 1.5rem;
    }
}