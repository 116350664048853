.manifesto__main__container {
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    width: 100%;
}

.manifesto__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    max-width: var(--max-width-container);
    padding: 6rem;
}

.manifesto__container-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.manifesto__container-content-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    max-width: 700px;
}

#man_txt {
    color: var(--white);
}

#man_h1 {
    color: var(--white);
}

/* #man_txt {
    opacity: 1;
    margin-bottom: 2rem;
    max-width: 750px;
}

#hp_h1, #hp_btn, #hp_character {
    opacity: 1;
}

#hp_h2 {
    opacity: 1;
    font-size: var(--header-size);
} */

.text-highlight {
    color: var(--violet);
}

.manifesto__container-content-image {
    height: 589.5px;
}

.manifesto__container-content-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.manifesto__container-content-image img {
    height: 100%;
}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .manifesto__container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        max-width: var(--max-width-container);
        padding: 2rem 2rem 4rem 2rem;
    }

    #man_h1 {
        margin-bottom: 2rem;
    }

}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .manifesto__container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        max-width: var(--max-width-container);
        padding: 2rem 2rem 4rem 2rem;
    }

    #man_h1 {
        margin-bottom: 2rem;
    }

}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .manifesto__container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        max-width: var(--max-width-container);
        padding: 2rem 2rem 4rem 2rem;
    }

    #man_h1 {
        margin-bottom: 2rem;
    }

}