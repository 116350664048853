@import url("https://use.typekit.net/exk3wdq.css");

:root {
  --violet:#A760FF;
  --white:#f1f2f4;
  --black:#040002;
  --blue:#5464FF;
  --rose:#FE6EFF;

  --navbar-color:black;
  --navbar-manifesto-color:white;

  --fading-duration: 600ms;

  --header-size: 6.5rem;
  --header-size-smartphone: 3.5rem;
  --header-size-tiny-screen: 4.5rem;
  --body-text-size: 1rem;
  --body-text-size-smartphone: 1rem;
  --body-text-size-tiny-screen: 1rem;

  --max-width-container: 1800px;

  --card-width-normal: 460px;

  /* NAVBAR POSITIONING */
  --navbar-positioning: sticky;

  background-color: var(--black);
}