#hamburger-menu {
    width: 2rem;
    height: 1.75rem;
    display: block;
    border: none;
    background: linear-gradient(
      to bottom, 
      var(--navbar-color), var(--navbar-color) 20%, 
      rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 40%, 
      var(--navbar-color) 40%, var(--navbar-color) 60%, 
      rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 80%, 
      var(--navbar-color) 80%, var(--navbar-color) 100%
    );
}

.coso {
    visibilty: hidden;
    width: 1rem;
    height: 1rem;
    border: none;
    position:absolute;
    top: calc(4rem + 0.375rem);
    margin-right: calc(2rem + 0.375rem);
    background:
            linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#fff 45%,#fff 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
            linear-gradient(135deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#fff 45%,#fff 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%);

    transition: 250ms ease-in-out;
}

#hamburger-menu #sidebar-menu {
    visibilty: hidden;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--violet);
    padding: 4rem 2rem;
    box-sizing: border-box;
    transition: 250ms ease-in-out;

    /* background-image: url('../../assets/home_character_large.png');
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size:contain;
    background-origin: content-box; */
}

#hamburger-input:checked + #hamburger-menu #sidebar-menu, .coso {
    visibility: visible;
    right: 0;
}

#hamburger-menu ul {
    padding-left: 0px;
  }
  
  #hamburger-menu li {
    list-style-type: none;
    line-height: 3rem;
    margin-bottom: 1rem;
  }
  
  #hamburger-menu a {
    color: var(--white);
    font-size: 1.5rem;
    text-decoration: none;

    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1px;
  }
  
  #hamburger-menu a:hover {
    text-decoration: underline;
  }
  
#hamburger-input{
    display: none;
}

.moodin-brgmn {
    color: var(--white) !important;
    margin-bottom: 4rem;
}

.hamburger__social-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    bottom: 2rem;
    position: absolute;
    gap: 2rem;
}