.collection__container {
    width: 100%;
    height: 100vh;
    
    background-color: var(--black);
    color: var(--white);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    padding: 6rem;
}

.collection__container-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: var(--max-width-container);
}

.collection__container-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.collection__container-content-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.collection__container-content-main h2 {
    margin-top: -1.25rem;
    margin-bottom: 3rem;
}

#col_txt {
    opacity: 1;
    max-width: 750px;
}

#col_h1, 
#col_h2 {
    opacity: 1;
}





/* LOW-RES SCREEN TARGET  */
@media screen and ( max-width: 1024px ) {

    .collection__container-grid {
        display: grid;
        /* grid-template-columns: repeat(2, 1fr); */
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        max-width: 300px;
        gap: 0.5rem;
        order: 1;
        align-self: auto;

        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
    }

    #col_h1 {
        order: 2;
        margin-top: 2rem;
    }
    
    #col_h2 {
        order: 3;
    }

    #col_txt {
        order: 4;
        max-width: 95%;
        margin-bottom: 2rem;
    }

    #nftcard_9, #nftcard_8, #nftcard_6 {
        display: none;
    }

}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .collection__container {
        width: 100%;
        height: auto;
        
        background-color: var(--black);
        color: var(--white);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }
    
    .collection__container-content {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        max-width: var(--max-width-container);
    }

    .collection__container-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        max-width: 90%;
        order: 2;
        align-self: auto;

        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
    }

    .collection__container-content-main h1 {
        margin-top: 2rem;
    }

    .collection__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #col_h1 {
        order: 1;
        margin-top: 2rem;
    }
    
    #col_h2 {
        order: 2;
    }

    #col_txt {
        order: 4;
        max-width: 95%;
        margin-bottom: 2rem;
    }

    #nftcard_9, #nftcard_8, #nftcard_6 {
        display: block;
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .collection__container {
        width: 100%;
        height: auto;
        
        background-color: var(--black);
        color: var(--white);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }
    
    .collection__container-content {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        max-width: var(--max-width-container);
    }

    .collection__container-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        max-width: 90%;
        order: 2;
        align-self: auto;

        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
    }

    .collection__container-content-main h1 {
        margin-top: 2rem;
    }

    .collection__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #col_h1 {
        order: 1;
        margin-top: 2rem;
    }
    
    #col_h2 {
        order: 2;
    }

    #col_txt {
        order: 4;
        max-width: 95%;
        margin-bottom: 2rem;
    }
}




/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .collection__container {
        width: 100%;
        height: auto;
        
        background-color: var(--black);
        color: var(--white);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }
    
    .collection__container-content {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        max-width: var(--max-width-container);
    }

    .collection__container-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        max-width: 90%;
        order: 2;
        align-self: auto;

        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
    }

    .collection__container-content-main h1 {
        margin-top: 2rem;
    }

    .collection__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #col_h1 {
        order: 1;
        margin-top: 2rem;
    }
    
    #col_h2 {
        order: 2;
    }

    #col_txt {
        order: 4;
        max-width: 95%;
        margin-bottom: 2rem;
    }
}