.footer__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    background-color: var(--black);
    padding: 6rem;
    width: 100%;

    border-top: 2px solid var(--white);
}

.footer__container-left {
    display: flex;
    flex-direction: column;
    gap: 6rem;
}

.footer__container-left-buttons {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.footer__container-right {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.footer__container-right-sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    text-align: right;

    gap: 0.5rem;

    height: 100%;

    color: var(--white);
}

.footer__container-right-sections h5 {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
}

.footer-sections-links {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.75rem;

    color: var(--white);
    text-decoration: none;
    
    display: inline-block;
    position: relative;

    transition: 250ms ease-in-out;
}
  
.footer-sections-links:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--white);
    transform-origin: bottom right;
    transition: transform 0.25s ease-in-out;
}
  
.footer-sections-links:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


.footer__container-right-socials {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.join_com_btn {
    width: 280px !important;
}

.logo-footer {
    font-size: 2rem;
    cursor: pointer;
    color: var(--white) !important;
}

.footer_link-social_icon {
    color: var(--white);
    font-size: 1rem;
}

.footer-twitter-icon,
.footer-instagram-icon,
.footer-discord-icon {
    padding: 0.75rem;
    border: 0;
    border-radius: 50px;
    transition: 0.25s ease-in-out;
}

.footer-twitter-icon:hover {
    background-color: #1D9BF0;
    color: var(--white);
}

.footer-instagram-icon:hover {
    background-color: #E1306C;
    color: var(--white);
}

.footer-discord-icon:hover {
    background-color: #5865F2;
    color: var(--white);
}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .footer__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    
        background-color: var(--black);
        padding: 2rem 0rem;
        width: 100%;
    
        border-top: 2px solid var(--white);
    }

    .footer__container-left {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        order: 1;
    }

    .footer__container-left-buttons {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    #fot_logo {
        margin-top: 2rem;
    }

    #fot_lbt,
    #fot_jbt {
        display: none;
    }

    .footer__container-right {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        order: 1;

        width: 100%;
        text-align: center;
    }

    .footer-sections-links {
        font-family: neue-haas-grotesk-display, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.25rem;
    
        color: var(--white);
        text-decoration: none;

        /* border-top: 1px solid var(--white); */
        /* border-bottom: 1px solid var(--white); */
        
        display: inline-block;
        position: relative;
    
        transition: 250ms ease-in-out;

        width: 100%;
        padding: 1rem;
    }

    .footer__container-right-sections {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        text-align: center;
        
        gap: 0rem;
        
        height: 100%;
        
        color: var(--white);
    }

    .footer__container-right-sections h5 {
        /* margin-bottom: 2rem;
        padding: 1rem; */
        display: none;
        /* border-bottom: 1px solid var(--white);
        border-top: 1px solid var(--white); */
    }

    .footer__container-right-socials {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4rem;
        justify-content: center;

        margin-bottom: 4rem;
    }

    .footer_link-social_icon {
        color: var(--white);
        font-size: 1.5rem;
    }
}



/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .footer__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    
        background-color: var(--black);
        padding: 2rem 0rem;
        width: 100%;
    
        border-top: 2px solid var(--white);
    }

    .footer__container-left {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        order: 1;
    }

    .footer__container-left-buttons {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    #fot_logo {
        margin-top: 2rem;
    }

    #fot_lbt,
    #fot_jbt {
        display: none;
    }

    .footer__container-right {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        order: 1;

        width: 100%;
        text-align: center;
    }

    .footer-sections-links {
        font-family: neue-haas-grotesk-display, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.25rem;
    
        color: var(--white);
        text-decoration: none;

        /* border-top: 1px solid var(--white); */
        /* border-bottom: 1px solid var(--white); */
        
        display: inline-block;
        position: relative;
    
        transition: 250ms ease-in-out;

        width: 100%;
        padding: 1rem;
    }

    .footer__container-right-sections {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        text-align: center;
        
        gap: 0rem;
        
        height: 100%;
        
        color: var(--white);
    }

    .footer__container-right-sections h5 {
        /* margin-bottom: 2rem;
        padding: 1rem; */
        display: none;
        /* border-bottom: 1px solid var(--white);
        border-top: 1px solid var(--white); */
    }

    .footer__container-right-socials {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4rem;
        justify-content: center;

        margin-bottom: 4rem;
    }

    .footer_link-social_icon {
        color: var(--white);
        font-size: 1.5rem;
    }
}