* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior:smooth;
}

body {
  background-color: var(--white);

  font-family: neue-haas-grotesk-display,sans-serif;
  font-weight: 500;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.text-highlight {
  color: var(--violet);
}

.text-highlight-blue {
  color: var(--blue);
}

.text-highlight-rose {
  color: var(--rose);
}

.text-lowop {
  opacity: 0.6;
}

.logo-text {
  font-family: variex,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.header-text {
  font-family: gopher,sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: var(--header-size);
}

.body-text {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 130%;
  font-size: var(--body-text-size);
  letter-spacing: 0.75px;
}

.litepaper-btn {
  width: 200px;
  background-color: var(--violet);
  border: 0;
  border-radius: 50px;

  color: var(--white);

  font-family: gopher,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-size: 1rem;
  padding: 1rem;

  cursor: pointer;
}

.arrow-button,
.arrow-button-white,
.arrow-button-violet {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrow-button::after {
  content: "";
  display: block;

  width: 0px;
  height: 16px;
  overflow: hidden;
  background-image: url('./assets/right-arrow.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  transition: width 200ms, margin-left 200ms;
}

.arrow-button-white::after {
  content: "";
  display: block;

  width: 0px;
  height: 16px;
  overflow: hidden;
  background-image: url('./assets/right-arrow-white.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  transition: width 200ms, margin-left 200ms;
}

.arrow-button-violet::after {
  content: "";
  display: block;

  width: 0px;
  height: 16px;
  overflow: hidden;
  background-image: url('./assets/right-arrow-violet.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  transition: width 200ms, margin-left 200ms;
}

.arrow-button:is(:hover, :focus)::after,
.arrow-button-white:is(:hover, :focus)::after,
.arrow-button-violet:is(:hover, :focus)::after {
  width: 16px;
  margin-left: 1rem;
  background-repeat: no-repeat;
}

.btn_link_no_deco {
    text-decoration: none !important;
    color: inherit;
}

.inactive-comp {
  color: grey !important;
  pointer-events: none !important;
}

.inactive-comp:hover {
  background-color: transparent !important;
  color: grey !important;
}

/* ANIMATIONS */
@keyframes fade_in_content {
  from {
      opacity: 0;
      transform: translateY(100px)
  }

  to {
      opacity: 1;
      transform: translate(0px);
  }
}

@keyframes fade_in_content-from-top {
  from {
      opacity: 0;
      transform: translateY(-100px)
  }

  to {
      opacity: 1;
      transform: translate(0px);
  }
}

@keyframes fade_out_content {
  from {
      opacity: 1;
  }

  to {
      opacity: 0;
      transform: translateY(100px);
  }
}

.fading-in-content-1 {
  animation: fade_in_content var(--fading-duration) 1 ease-out forwards;
  /*
      animation-name: fade_in_content;
      animation-duration: var(--fading-duration);
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
  */
}
.fading-in-content-2 {
  animation: fade_in_content calc( var(--fading-duration) + 100ms ) 1 ease-out forwards;
}
.fading-in-content-3 {
  animation: fade_in_content calc( var(--fading-duration) + 200ms ) 1 ease-out forwards;
}
.fading-in-content-4 {
  animation: fade_in_content calc( var(--fading-duration) + 300ms ) 1 ease-out forwards;
}
.fading-in-content-5 {
  animation: fade_in_content calc( var(--fading-duration) + 400ms ) 1 ease-out forwards;
}
.fading-in-content-from-top-1 {
  animation: fade_in_content-from-top var(--fading-duration) 1 ease-out forwards;
  /*
      animation-name: fade_in_content;
      animation-duration: var(--fading-duration);
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
  */
}
.fading-in-content-from-top-2 {
  animation: fade_in_content-from-top calc( var(--fading-duration) + 100ms ) 1 ease-out forwards;
}
.fading-in-content-from-top-3 {
  animation: fade_in_content-from-top calc( var(--fading-duration) + 200ms ) 1 ease-out forwards;
}
.fading-in-content-from-top-4 {
  animation: fade_in_content-from-top calc( var(--fading-duration) + 300ms ) 1 ease-out forwards;
}
.fading-in-content-from-top-5 {
  animation: fade_in_content-from-top calc( var(--fading-duration) + 400ms ) 1 ease-out forwards;
}
.fading-out-content-1 {
  animation: fade_out_content calc( var(--fading-duration) + 1000ms ) 1 linear forwards;
  /*
  animation-name: fade_out_content;
  animation-duration: var(--fading-duration);
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  */
}
.fading-out-content-2 {
  animation: fade_out_content calc( var(--fading-duration) + 800ms ) 1 linear forwards;
}
.fading-out-content-3 {
  animation: fade_out_content calc( var(--fading-duration) + 600ms ) 1 linear forwards;
}
.fading-out-content-4 {
  animation: fade_out_content calc( var(--fading-duration) + 400ms ) 1 linear forwards;
}
.fading-out-content-5 {
  animation: fade_out_content var(--fading-duration) 1 linear forwards;
}





/* LOW-RES SCREEN TARGET  */
@media screen and ( max-width: 1024px ) {
  .body-text {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 160%;
    font-size: var(--body-text-size-tiny-screen);
    letter-spacing: 0.75px;
  }

  .header-text {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: var(--header-size-tiny-screen);
  }

}




/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

  .body-text {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 160%;
    font-size: var(--body-text-size-smartphone);
    letter-spacing: 0.75px;
  }

  .header-text {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: var(--header-size-smartphone);
  }

}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

  .body-text {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 160%;
    font-size: var(--body-text-size-smartphone);
    letter-spacing: 0.75px;
  }

  .header-text {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: var(--header-size-smartphone);
  }

}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

  .body-text {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 160%;
    font-size: var(--body-text-size-smartphone);
    letter-spacing: 0.75px;
  }

  .header-text {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: var(--header-size-smartphone);
  }

}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

  .body-text {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 160%;
    font-size: var(--body-text-size-smartphone);
    letter-spacing: 0.75px;
  }

  .header-text {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: var(--header-size-smartphone);
  }

}