.team-card__container {
    background-color: var(--violet);
    border: 0;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    height: 360px;
    width: 360px;

    padding: 2rem;
    gap: 1rem;

    color: var(--white);

    font-family: neue-haas-grotesk-display,sans-serif;
    font-weight: 500;
    font-style: normal;
}

.team-card__image {
    flex: 5;
}

.team-card__container__upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
}

.nft__card {
    height: 190px;
    width: 190px;

    border: 0;
    border-radius: 20px;

    opacity: 1;
}

.nft__card img {
    background-size: 190px;
    height: 190px;
    object-fit: fill;

    border: 0;
    border-radius: 20px;
}

.team-card-icon {
    color: var(--white);
    font-size: 1.25rem;
}

.team-card__container__lower {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.team-card__container__lower-desc {
    
    font-size: 1rem;

}

#tm-mbm {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .team-card__container {
        background-color: var(--violet);
        border: 0;
        border-radius: 8px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        height: 100%;
        width: 100%;
        aspect-ratio: 1/1;
    
        padding: 2rem;
        gap: 1rem;
    
        color: var(--white);
    
        font-family: neue-haas-grotesk-display,sans-serif;
        font-weight: 500;
        font-style: normal;

        justify-self: stretch;
    }

    .team-card__container__upper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .team-card__container {
        background-color: var(--violet);
        border: 0;
        border-radius: 8px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        height: 100%;
        width: 100%;
        aspect-ratio: 1/1;
    
        padding: 2rem;
        gap: 1rem;
    
        color: var(--white);
    
        font-family: neue-haas-grotesk-display,sans-serif;
        font-weight: 500;
        font-style: normal;

        justify-self: stretch;
    }

    .team-card__container__upper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
    }
}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .team-card__container {
        background-color: var(--violet);
        border: 0;
        border-radius: 8px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        height: auto;
        width: 100%;
        aspect-ratio: 1/1;
    
        padding: 2rem;
        gap: 1rem;
    
        color: var(--white);
    
        font-family: neue-haas-grotesk-display,sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .team-card__container__upper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
    }
}