.distribution__container {
    width: 100%;
    height: 100vh;
    
    background-color: var(--black);
    color: var(--white);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    padding: 6rem;
}

.distribution__container-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: var(--max-width-container);
}

.distribution__container-grid {
    height: 664px;
    width: 664px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.distribution__container-content-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.distribution__container-content-main h2 {
    margin-top: -1.25rem;
    margin-bottom: 3rem;
}

#dis_txt {
    opacity: 1;
    /* opacity: 1; */
    max-width: 750px;
}

#dis_h1, #dis_h2 {
    opacity: 1;
    /* opacity: 1; */
}

.distribution__container-content-image {
    height: 550px;
}

.distribution__container-content-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.distribution__container-content-image img {
    height: 100%;
}





/* LOW-RES SCREEN TARGET  */
@media screen and ( max-width: 1600px ) {

    .distribution__container-content-image {
        height: 100%;
    }
    
    .distribution__container-content-image {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: row;
    }
    
    .distribution__container-content-image img {
        width: 30vw;
        height: auto;
    }

}





/* LOW-RES SCREEN TARGET  */
@media screen and ( max-width: 1024px ) {

    .distribution__container-content-image {
        height: 100%;
    }
    
    .distribution__container-content-image {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: row;
    }
    
    .distribution__container-content-image img {
        width: 80%;
        height: auto;
    }

}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .distribution__container {
        width: 100%;
        height: auto;
        
        background-color: var(--black);
        color: var(--white);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }
    
    .distribution__container-content {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        max-width: var(--max-width-container);
    }

    .distribution__container-content-image {
        height: auto;
        width: 100%;
    }
    
    .distribution__container-content-image {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        margin-bottom: 2rem;
    }
    
    .distribution__container-content-image img {
        /* height: 100%; */
        object-fit: cover;
        width: 75%;
        max-height: 100%;
        margin: auto;

        aspect-ratio: 1/1; 
    }

    .collection__container-content-main h1 {
        margin-top: 2rem;
    }

    .collection__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #dis_h1 {
        margin-top: 2rem;
    }

    #dis_txt {
        order: 4;
        max-width: 95%;
        margin-bottom: 2rem;
    }

}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .distribution__container {
        width: 100%;
        height: auto;
        
        background-color: var(--black);
        color: var(--white);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }
    
    .distribution__container-content {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        max-width: var(--max-width-container);
    }

    .distribution__container-content-image {
        height: auto;
        width: 100%;
    }
    
    .distribution__container-content-image {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        margin-bottom: 2rem;
    }
    
    .distribution__container-content-image img {
        /* height: 100%; */
        object-fit: cover;
        width: 75%;
        max-height: 100%;
        margin: auto;

        aspect-ratio: 1/1; 
    }

    .collection__container-content-main h1 {
        margin-top: 2rem;
    }

    .collection__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #dis_h1 {
        margin-top: 2rem;
    }

    #dis_txt {
        order: 4;
        max-width: 95%;
        margin-bottom: 2rem;
    }

}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .distribution__container {
        width: 100%;
        height: auto;
        
        background-color: var(--black);
        color: var(--white);
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    
        padding: 2rem;
    }
    
    .distribution__container-content {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        max-width: var(--max-width-container);
    }

    .distribution__container-content-image {
        height: auto;
        width: 100%;
    }
    
    .distribution__container-content-image {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        margin-bottom: 2rem;
    }
    
    .distribution__container-content-image img {
        /* height: 100%; */
        object-fit: cover;
        width: 100%;
        max-height: 100%;

        aspect-ratio: 1/1; 
    }

    .collection__container-content-main h1 {
        margin-top: 2rem;
    }

    .collection__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    #dis_h1 {
        margin-top: 2rem;
    }

    #dis_txt {
        order: 4;
        max-width: 95%;
        margin-bottom: 2rem;
    }

}