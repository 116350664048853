.homepage__container {
    width: 100vw;
    height: calc( 100vh - 100px );
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 6rem;
}

.homepage__container-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: var(--max-width-container);
}

.homepage__container-content-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.homepage__container-content-main h2 {
    color: var(--violet);
    margin-top: -1.25rem;
    margin-bottom: 3rem;
}

#hp_txt {
    opacity: 1;
    margin-bottom: 2rem;
    max-width: 750px;
}

#hp_h1, #hp_btn, #hp_character {
    opacity: 1;
}

#hp_h2 {
    opacity: 1;
    font-size: var(--header-size);
}

.homepage__container-content-image {
    height: 589.5px;
}

.homepage__container-content-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.homepage__container-content-image img {
    height: 100%;
}





/* LOW-RES SCREEN TARGET  */
@media screen and ( max-width: 1024px ) {

    .homepage__container-content-image {
        height: auto;
        width: auto;
    }
    
    .homepage__container-content-image {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
    }
    
    .homepage__container-content-image img {
        width: 80%;
        height: auto;
    }

}






/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .homepage__container {
        width: 100%;
        height: auto;
        padding: 2rem;
    }

    .homepage__container-content-image {
        display: none;
    }

    .homepage__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }
  
    #hp_txt {
        margin-bottom: 2rem;
        max-width: 95%;
    }

    #hp_h2 {
        font-size: var(--header-size-smartphone);
    }

    #hp_btn {
        margin-bottom: 2rem;
    }

  }





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .homepage__container {
        width: 100%;
        height: auto;
        padding: 2rem;
    }

    .homepage__container-content-image {
        display: none;
    }

    .homepage__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }
  
    #hp_txt {
        margin-bottom: 2rem;
        max-width: 95%;
    }

    #hp_h2 {
        font-size: var(--header-size-smartphone);
    }

    #hp_btn {
        margin-bottom: 2rem;
    }

  }






/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .homepage__container {
        width: 100%;
        height: auto;
        padding: 2rem;
    }

    .homepage__container-content-image {
        display: none;
    }

    .homepage__container-content-main h2 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }
  
    #hp_txt {
        margin-bottom: 2rem;
        max-width: 95%;
    }

    #hp_h2 {
        font-size: var(--header-size-smartphone);
    }

    #hp_btn {
        margin-bottom: 2rem;
    }

  }