.navbar__container {
    height: 100px;
    width: 100%;

    padding: 3.125rem 6rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;

    position: var(--navbar-positioning);
    top: 0;
    z-index: 10;

    color: var(--navbar-color);
}

.navbar__container-interno {
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-width-container);
}

.navbar__container-interno_sections {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    gap: 3.25rem;
}

.sticky {
    /* position: sticky;
    top: 0;
    width: 100%;

    z-index: 10; */
}

.logo {
    font-size: 2rem;
    cursor: pointer;
}

.link-btn {
    font-family: gopher,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;

    color: var(--navbar-color);

    background: none;
    background-color: transparent;
    border: 0;
    padding: 0.75rem;

    cursor: pointer;
}

.link-btn_text {
    display: inline-block;
    position: relative;

    transition: 250ms ease-in-out;
}
  
.link-btn_text:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--navbar-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-in-out;
}
  
.link-btn_text:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.active-btn-page {
    display: inline-block;
    position: relative;

    pointer-events: none;
}

.active-btn-page::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--navbar-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-in-out;
}

.link-social_icon {
    color: var(--navbar-color);
    font-size: 1.25rem;
}

.twitter-icon,
.instagram-icon,
.discord-icon {
    padding: 0.75rem;
    border: 0;
    border-radius: 50px;
    transition: 0.25s ease-in-out;
}

.twitter-icon:hover {
    background-color: #1D9BF0;
    color: var(--white);
}

.instagram-icon:hover {
    background-color: #E1306C;
    color: var(--white);
}

.discord-icon:hover {
    background-color: #5865F2;
    color: var(--white);
}


.navbar-complete {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    gap: 3.25rem;
}

.navbar-boorgir {
    display: none;
}





/* TABLET TARGET  */
@media screen and ( max-width: 1200px ) {
    .navbar__container {
        height: 153px;
        padding: 4rem 2rem;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        position: var(--navbar-positioning);
        top: 0;
        z-index: 10;
    
        color: var(--navbar-color);
    }

    .navbar__container-interno_sections {
        /* display: none; */
        /* flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    
        gap: 3.25rem; */
    }

    .navbar-complete {
        display: none;
    }

    .navbar-boorgir {
        display: block;
    }

}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {
    .navbar__container {
        height: 153px;
        padding: 4rem 2rem;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        position: var(--navbar-positioning);
        top: 0;
        z-index: 10;
    
        color: var(--navbar-color);
    }

    .navbar__container-interno_sections {
        /* display: none; */
        /* flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    
        gap: 3.25rem; */
    }

}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {
    .navbar__container {
        height: 153px;
        padding: 4rem 2rem;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        position: var(--navbar-positioning);
        top: 0;
        z-index: 10;
    
        color: var(--navbar-color);
    }

    .navbar__container-interno_sections {
        /* display: none; */
        /* flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    
        gap: 3.25rem; */
    }

}