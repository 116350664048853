.mainscreen__container {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    background-color: var(--violet);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 999;
}

.mainscreen__container-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 2rem;
    
    color: var(--white);
}


.mainscreen__container-content h1 {
    font-size: 10rem;
}

.mainscreen-subheader {
    text-align: center;
    font-weight: 400;
    max-width: 800px;
}

.mainscreen-button {
    font-family: gopher,sans-serif;
    font-weight: 500;
    font-style: normal;
    
    font-size: 1.25rem;
    color: var(--violet);
    
    background-color: var(--white);
    border: 0;
    border-radius: 50px;
    padding: 1rem 0;
    
    width: 260px;
    
    margin-top: 1rem;
    
    animation: btn_pulse 1600ms infinite ease-in-out forwards;
    /*
    animation-name: fade_in_content;
    animation-duration: var(--fading-duration);
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    */
}

.mainscreen-button:hover {
    animation: btn_hovered 200ms 1 ease-in-out forwards;
}

@keyframes btn_hovered {
    from {
        opacity: 1;
    }
    to {
        opacity: 1;
    }
}

@keyframes btn_pulse {
    from { 
        opacity: 0.8;
    }

    50% {
        opacity: 1;
    }

    to { 
        opacity: 0.8; 
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {
    .mainscreen__container-content h1 {
        font-size: 4rem;
    }

    .mainscreen-subheader {
        text-align: center;
        font-weight: 400;
        max-width: 90%;
    }

    .mainscreen-button {        
        font-size: 1rem;
        padding: 1rem 0;
        width: 220px;
        margin-top: 0.75rem;
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {
    .mainscreen__container-content h1 {
        font-size: 4rem;
    }

    .mainscreen-subheader {
        text-align: center;
        font-weight: 400;
        max-width: 90%;
    }

    .mainscreen-button {        
        font-size: 1rem;
        padding: 1rem 0;
        width: 220px;
        margin-top: 0.75rem;
    }
}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {
    .mainscreen__container-content h1 {
        font-size: 4rem;
    }

    .mainscreen-subheader {
        text-align: center;
        font-weight: 400;
        max-width: 90%;
    }

    .mainscreen-button {        
        font-size: 1rem;
        padding: 1rem 0;
        width: 220px;
        margin-top: 0.75rem;
    }
}