.comingsoon__container {
    width: 100%;
    height: calc( 100vh - 100px);
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 6rem;
}

.coming-soon-text {
    font-size: var(--header-size);
    color: var(--violet);
    text-align: center;
}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .comingsoon__container {
        /* width: 100%;
        height: 100vh;
        background-color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
    
        padding: 2rem; */
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .coming-soon-text {
        font-size: var(--header-size-smartphone);
        color: var(--violet);
        text-align: center;
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .comingsoon__container {
        /* width: 100%;
        height: 100vh;
        background-color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
    
        padding: 2rem; */
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .coming-soon-text {
        font-size: var(--header-size-smartphone);
        color: var(--violet);
        text-align: center;
    }
}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .comingsoon__container {
        /* width: 100%;
        height: 100vh;
        background-color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
    
        padding: 2rem; */
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .coming-soon-text {
        font-size: var(--header-size-smartphone);
        color: var(--violet);
        text-align: center;
    }
}