.nft__card {
    height: 190px;
    width: 190px;

    border: 0;
    border-radius: 20px;

    opacity: 1;
}

.nft__card img {
    background-size: 190px;
    height: 190px;
    object-fit: fill;

    border: 0;
    border-radius: 20px;
}





/* LOW-RES SCREEN TARGET  */
@media screen and ( max-width: 1600px ) {

    .nft__card {
        height: 10vw;
        width: 10vw;
    
        border: 0;
        border-radius: 20px;
    
        opacity: 1;
    }
    
    .nft__card img {
        background-size: 10vw;
        height: 10vw;
        width: 10vw;
        object-fit: fill;
    
        border: 0;
        border-radius: 20px;
    }

}





/* LOW-RES SCREEN TARGET  */
@media screen and ( max-width: 1024px ) {

    .nft__card {
        height: auto;
        width: auto;
        border: 0;
        border-radius: 8px;
        opacity: 1;

        aspect-ratio: 1/1; 
    }
    
    .nft__card img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        max-height: 100%;

        aspect-ratio: 1/1; 
    }

}





/* TABLET TARGET  */
@media screen and ( max-width: 900px ) {

    .nft__card {
        height: auto;
        width: auto;
        border: 0;
        border-radius: 8px;
        opacity: 1;

        aspect-ratio: 1/1; 
    }
    
    .nft__card img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;

        aspect-ratio: 1/1; 
    }
}





/* TABLET TARGET  */
@media screen and ( max-width: 768px ) {

    .nft__card {
        height: auto;
        width: auto;
        border: 0;
        border-radius: 8px;
        opacity: 1;

        aspect-ratio: 1/1; 
    }
    
    .nft__card img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;

        aspect-ratio: 1/1; 
    }
}





/* SMARTPHONE TARGET  */
@media screen and ( max-width: 480px ) {

    .nft__card {
        height: auto;
        width: auto;
        border: 0;
        border-radius: 8px;
        opacity: 1;

        aspect-ratio: 1/1; 
    }
    
    .nft__card img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;

        aspect-ratio: 1/1; 
    }
}